<template>
  <div>
    <ChartBox title="问题整改完成率">
      <div style="width: 100%; height: 100%; position: relative">
        <Chart width="60%" height="88%" :option="storeManager"></Chart>
        <div
          style="
            font-size: 16px;
            color: white;
            position: absolute;
            right: 25px;
            top: 45px;
            font-weight: bolder;
          "
        >
          <div
            style="
              width: 120px;
              height: 60px;
              border: 1px solid rgb(121, 136, 151);
              border-radius: 5px;
              text-align: center;
              line-height: 25px;
            "
          >
            <div style="margin-top: 4px">{{ completed_tasks }}</div>
            <div style="margin-top: 2px; color: #999; font-size: 14px">
              整改问题总数
            </div>
          </div>
          <div
            style="
              width: 120px;
              height: 60px;
              border: 1px solid rgb(121, 136, 151);
              border-radius: 5px;
              text-align: center;
              line-height: 25px;
              margin-top: 30px;
            "
          >
            <div style="margin-top: 4px">{{ total_tasks }}</div>
            <div style="margin-top: 2px; color: #999; font-size: 14px">
              已完成问题总数
            </div>
          </div>
        </div>
      </div>
    </ChartBox>
  </div>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getTaskDoRate, getProblemDoneRate } from "../api/api";
export default {
  data() {
    return {
      storeManager: {}, //店长助手完成率配置情况
      completion_percentage: "", //每日任务完成率
      total_tasks: "", //计划完成总数
      completed_tasks: "", //每日任务完成数
    };
  },
  components: {
    ChartBox,
    Chart,
  },
  methods: {
    // 获取问题整改完成率
    async getProblemDoneRate() {
      const res = await getProblemDoneRate();
      this.completion_percentage = res.data.data.done_rate;
      this.total_tasks = res.data.data.amount;
      this.completed_tasks = res.data.data.done_amount;
      this.setstoreManager();
    },
    setstoreManager() {
      const rate = `${this.completion_percentage}%`;
      const radius = 110;
      const endPointRadius = [radius / 2 - 2 + "%", radius / 2 + "%"];
      const diffDegree = 360 / 360;
      this.storeManager = {
        title: [
          {
            text: rate,
            textStyle: {
              fontSize: 16,
              color: "rgb(42,165,21)",
              fontWeight: 600,
            },
            x: "center",
            y: "center",
          },
          {
            text: "问题整改完成率  ",
            textStyle: {
              fontSize: 15,
              color: "#999",
              fontWeight: 600,
            },
            x: "center",
            y: "90%",
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 360, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: radius + "%",
        },
        series: [
          {
            name: "上层绿环",
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: parseInt(rate.slice(0, -1)),
                name: "sdfasdf",
                itemStyle: {
                  color: "rgb(42,165,21)",
                },
              },
            ],
            barGap: "-100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2,
          },
          {
            //下层圆环，显示最大值
            name: "下层阴影环",
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "rgba(255,255,255,0.2)",
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          // 带空心的小圆点
          {
            type: "pie",
            radius: endPointRadius,
            zlevel: 10,
            silent: true,
            startAngle: 225,
            data: [
              {
                name: "",
                value: (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(0,0,0,0)",
                },
              },

              // 画中间的图标
              {
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: "#fff",
                  borderRadius: 12,
                  padding: 5, // 可以控制圆的大小
                  borderWidth: 3,
                  borderColor: "rgba(42,165,21)",
                },
              },
              {
                name: "",
                value:
                  diffDegree - (parseInt(rate.slice(0, -1)) / 100).toFixed(2),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(255,255,255,0)",
                },
              },
            ],
          },
        ],
      };
    },
  },
  mounted() {
    this.getProblemDoneRate();
  },
  watch: {},
};
</script>

<style></style>
