import largesScreen from "../page/largesScreen.vue";
import MyLogin from "../page/MyLogin.vue";
import tableDetail from "../page/tableDetail.vue";
import largesScreenMonitor from "../page/largesScreen1.vue";
import warehouseLedger from "../page/warehouseLedger.vue";
import warehouseLedgerERP from "../page/warehouseLedgerERP.vue";
import healthyList from "../page/healthyList.vue";
import temperatureAndHumidity from "../page/temperatureAndHumidity.vue";
import internalControl from "../page/internalControlLarge.vue";
import assistantManager from "../page/assistantManager.vue";
import basicVersion from "../page/basicVersion.vue";
import monitor from "../page/monitor.vue"
import shelfLife from "../page/shelfLife.vue";
import Prevention from "../page/Prevention.vue";
import RectificationRecords from "../page/rectificationRecords.vue";
import timeAxis from "../page/timeAxis.vue";
import storage from "../page/storage.vue";
import nutritionalAnalysis from "../page/nutritionalAnalysis.vue";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);


export const constantRoutes = [
  {
    path: "/nutritionalAnalysis",
    name: "nutritionalAnalysis",
    component: nutritionalAnalysis,
  },
  {
    path:'/storage',
    name: "storage",
    component: storage,
  },
  {
    path: "/larges",
    name: "largesScreen",
    component: largesScreen,
  },
  {
    path: "/login",
    component: MyLogin,
  },
  {
    path: "/tableDetail",
    component: tableDetail,
  },
  {
    path: "/larges2",
    component: largesScreenMonitor,
  },
  {
    path: "/ProductEntry",//产品朔源 ProductEntry
    component: warehouseLedger,
  },
  {
    path: "/ERPProductEntry",//产品朔源ERP ERPProductEntry
    component: warehouseLedgerERP,
  },
  {
    path: "/healthyList",
    component: healthyList,
  },
  {
    path: "/IoTMonitoring",//物联网 IoTMonitoring
    component: temperatureAndHumidity,
  },
  {
    path: "/InterControl",//内控屏 InterControl
    component: internalControl,
  },
  {
    path: "/assistantManager",
    component: assistantManager,
  },
  {
    path: "/VideoMonitor",//明厨亮灶
    component: monitor,
  },
  {
    path: "/basicVersion",
    component: basicVersion,
  },
  {
    path: "/shelfLife",
    component: shelfLife,
  },
  {
    path: "/RatPrevention",//防鼠
    component: Prevention,
  },
  {
    path: "/rectificationRecords",
    component: RectificationRecords,
  },
  {
    path: "/StoreManager",//店长助手时间轴 StoreManager
    component: timeAxis,
  },
 
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
});
