<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 标题栏部分 -->
      <AiHeader ref="mychild" :outer="true"></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div data-title="主食吃对了吗 " class="box-left-one">
            <Chart style="width: 100%;" :option="ChartTemperature1"></Chart>
          </div>
          <div data-title="矿物质含量高的蔬菜" class="box-left-two">
          </div>
          <!-- 健康证展示 -->
          <div data-title="水果VC含量排行榜" class="box-left-three"></div>
        </aside>

        <section>
          <!-- 证件表格 -->
          <div data-title="主食吃对了吗" class="center-top"></div>
          <div data-title="主食吃对了吗" class="center-bottom"></div>
        </section>

        <article>
          <div class="box-left-four"></div>
          <!-- 视频监控  -->
          <div class="box-left-five">
          </div>
          <!-- 抓拍部分 -->
          <div class="box-left-six"></div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import StoreManager from "../components/storeManager.vue";
import Swiper from "../components/swiper.vue";
import { baseImgUrl } from "../utils/request";
import Rectification from "../components/rectification.vue";
import {} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

import * as echarts from "echarts";

export default {
  data() {
    return {
      ChartTemperature1: {}, //图表配置项（温度）
    };
  },
  mixins: [myMixin],
  methods: {
    setTemperature() {
      this.ChartTemperature1 = {
        backgroundColor: "#0f375f",
        grid: {
          top: "25%",
          bottom: "10%", //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        legend: {
          data: ["销售水量", "主营业务"],
          top: "15%",
          textStyle: {
            color: "#ffffff",
          },
        },
        xAxis: {
          data: [
            "当年完成水量",
            "去年同期水量",
            "滚动目标值水量",
            "全年目标值水量",
            "当年完成金额",
            "去年同期金额",
            "滚动目标金额",
            "全年目标值",
          ],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#01FCE3",
            },
          },
          axisTick: {
            show: true, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ebf8ac", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "亿元",
            nameTextStyle: {
              color: "#ebf8ac",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#FFFFFF",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ebf8ac",
              },
            },
          },
          {
            type: "value",
            name: "同比",
            nameTextStyle: {
              color: "#ebf8ac",
            },
            position: "right",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              formatter: "{value} %", //右侧Y轴文字显示
              textStyle: {
                color: "#ebf8ac",
              },
            },
          },
          {
            type: "value",
            gridIndex: 0,
            min: 50,
            max: 100,
            splitNumber: 8,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"],
              },
            },
          },
        ],
        series: [
          {
            name: "销售水量",
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#058cff",
            },
            lineStyle: {
              color: "#058cff",
            },
            areaStyle: {
              color: "rgba(5,140,255, 0.2)",
            },
            data: [4.2, 3.8, 4.8, 3.5, 2.9, 2.8, 3, 5],
          },
          {
            name: "主营业务",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00FFE3",
                  },
                  {
                    offset: 1,
                    color: "#4693EC",
                  },
                ]),
              },
            },
            data: [4.2, 3.8, 4.8, 3.5, 2.9, 2.8, 3, 5],
          },
        ],
      };
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
  },
  created() {
    this.setTemperature();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .box-left-one,
  .box-left-two,
  .box-left-three,
  .box-left-four,
  .box-left-five,
  .box-left-six,
  .center-top,
  .center-bottom {
    position: relative;
    border-radius: 5px;
    padding: 35px 20px;
    box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .box-left-one:before,
  .box-left-two:before,
  .box-left-three::before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 1;
  }

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span {
        font-size: 15px;
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 50px);
  > section,
  > aside,
  > article {
    flex: 0 0 30%;
    width: 0;
    > * {
      height: 230px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      box-sizing: border-box;
      height: 460px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
</style>
