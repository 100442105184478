<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 查看大图容器 -->
      <div class="imgwrap" v-if="isShowImg">
        <img class="next" src="../assets/image/next.png" @click="nextImage()" />
        <img class="up" src="../assets/image/up.png" @click="upImage()" />
        <img :src="bigPic" alt="点击关闭" @click="viewBack" />
      </div>
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :outer="true"
        :AiHeaderQuery="AiHeaderQuery"
        @childEvent="handleChildEvent"
      ></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div class="box-left-one">
            <ChartBox title="食堂信息">
              <div
                class="logo"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                "
              >
                <img
                  :src="shop_img"
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-left: 5px;
                    margin-top: 50px;
                  "
                />
                <div style="color: white">
                  <div
                    class="phone"
                    style="margin: 10px 0; width: 240px; font-weight: bolder"
                  >
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 10px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                  <div
                    class="shop_address"
                    style="line-height: 1.5; width: 240px"
                  >
                    <span>餐厅地址：</span>
                    <span>{{ shop_address }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- 企业宣传视频 -->
          <div class="box-left-two" v-if="module2 == 'videos'">
            <ChartBox :title="videoTitle">
              <div
                class="logo"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <video
                  controls
                  ref="myVideo"
                  muted="muted"
                  :loop="videoSources.length == 1 ? true : false"
                  autoplay="autoplay"
                  :src="videoSources[currentVideoIndex].video_url"
                  style="width: 100%; height: 230px; object-fit: cover"
                  @ended="playNextVideo"
                ></video>
              </div>
            </ChartBox>
          </div>
          <!-- 顾客建议 -->
          <div
            class="box-right-three"
            v-else-if="module2 == 'customerSuggestion'"
          >
            <ChartBox title="顾客建议">
              <div v-if="!suggestionContent.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in suggestionContent"
                  :key="index"
                >
                  <div class="right-li">
                    <el-image
                      class="img"
                      :src="item.images"
                      :preview-src-list="[item.images]"
                    ></el-image>
                    <div
                      style="
                        width: 100%;
                        padding: 6px 4px 4px 8px;
                        border-radius: 5px;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgba(39, 150, 28, 0.4);
                        bottom: 0;
                        left: 0;
                      "
                    >
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(189, 155, 25);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          顾客建议
                        </div>
                        ：{{ item.suggestio_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        建议日期：{{ item.created_time }}
                      </div>
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(166, 145, 92);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          餐厅回复
                        </div>
                        ：{{ item.feedback_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        回复日期：{{ item.feedback_user_time }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
          <div class="box-left-two" @click="goToTemperHumidity" v-else>
            <ChartBox title="温湿度监控" :tip="true">
              <div class="temperContainer">
                <div
                  class="tempItem"
                  v-for="(item, index) in locationTempList"
                  :key="index"
                >
                  <div class="location">{{ item.name }}</div>
                  <!-- 温度 -->
                  <div class="temper">
                    <!-- 正常 -->
                    <img
                      v-if="item.temp_status == '正常'"
                      src="../assets/image/normal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 警告 -->
                    <img
                      v-if="item.temp_status == '异常'"
                      src="../assets/image/abnormal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 报警 -->
                    <img
                      v-if="item.temp_status == '警告'"
                      src="../assets/image/warn1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <div class="temperNum">{{ item.temp }}℃</div>
                  </div>
                  <!-- 湿度 -->
                  <div class="water">
                    <!-- 正常 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '正常'"
                      src="../assets/image/fullwater.png"
                    />
                    <!-- 警告 wet_status -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '异常'"
                      src="../assets/image/warter2.png"
                    />
                    <!-- 报警 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '警告'"
                      src="../assets/image/warter3.png"
                    />
                    <div class="waterNum">{{ item.wet }}%</div>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- 健康证展示 -->
          <div class="box-left-three" @click="goToHealthyList">
            <ChartBox title="健康证展示" more="更多">
              <el-carousel height="230px" indicator-position="none">
                <el-carousel-item
                  v-for="(item, index) in healthList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <img
                      :src="
                        item.pic_image +
                        '?x-oss-process=image/resize,w_280,m_lfit'
                      "
                      alt=""
                      style="width: 270px; height: 130px; margin-top: 10px"
                    />
                    <div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                          margin: 5px 20px 10px 0;
                        "
                      >
                        健康证有效
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                        "
                      >
                        到期日期：{{ item.end_date }}
                      </div>
                    </div>
                    <div style="color: white; font-size: 12px">
                      <div class="heaName">姓名：{{ item.name }}</div>
                      <div class="heaName" style="margin: 5px 0">
                        健康证编号：{{ item.code }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </aside>

        <section>
          <!-- 证件表格 -->
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList="tabList"></TabPane>
          </div>
          <!-- 信息展示 @click="toshelfLife"-->
          <div class="healthCertificate">
            <ChartBox title="信息展示">
              <Swiper :dataList="dataList"></Swiper>
            </ChartBox>
          </div>
        </section>

        <article>
          <div class="box-left-one">
            <ChartBox title="投诉意见反馈">
              <div
                class="logo"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  style="border-radius: 10px; margin-top: 25px; width: 330px"
                  :src="suggestImg"
                  alt=""
                />
              </div>
            </ChartBox>
          </div>
          <!-- 没有AI的-->
          <div @click="toRectification" v-if="module5 == 'probolemDoneRate'">
            <Rectification style="width: 100%; height: 100%"></Rectification>
          </div>
          <!-- 视频监控  -->
          <div class="center-top" v-else>
            <ChartBox title="视频监控">
              <template v-slot:selectBox>
                <el-dropdown size="mini" split-button type="primary">
                  <span class="el-dropdown-link">
                    {{ value2 }}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, index) in option2"
                      :key="index"
                      @click.native="changeValue2(item.label)"
                    >
                      <span> {{ item.label }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <div class="one">
                <iframe
                  v-if="checked"
                  src="/iframe.html"
                  id="iframe"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
                <!-- <iframe
                  src="http://47.99.163.50:18080/#/play/wasm/ws%3A%2F%2F47.99.163.50%3A6080%2Frtp%2F41010500001110000001_41010500001310000104.live.flv"
                  width="350px"
                  height="210px"
                ></iframe> -->
                <el-carousel
                  v-else-if="isCarousel"
                  class="right-ul"
                  height="230px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(item, index) in currentImgList"
                    :key="index"
                  >
                    <div class="right-li">
                      <img
                        :src="item"
                        style="width: 100%; height: 240px; overflow: hidden"
                        @error="handleBImageError"
                        @click="viewLargeVideo(item, index)"
                      />
                    </div>
                  </el-carousel-item>
                </el-carousel>

                <img
                  style="width: 100%; height: 100%"
                  :src="currentImg || defaultImgB"
                  v-else
                  @error="handleBImageError"
                />
              </div>
            </ChartBox>
          </div>
          <!-- 问题整改 -->
          <div class="box-right-three" v-if="module6 == 'probolemShow'">
            <ChartBox title="问题整改详情">
              <div v-if="!rectificationContent.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in rectificationContent"
                  :key="index"
                >
                  <div class="right-li" style="">
                    <el-image
                      class="img"
                      style="width: 100%; margin-left: 10px"
                      :src="item.images"
                      :preview-src-list="[item.images_arr]"
                    ></el-image>
                    <div
                      style="
                        width: 85%;
                        padding: 6px 4px 4px 8px;
                        border-radius: 5px;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgba(39, 150, 28, 0.4);
                        bottom: 2px;
                        left: 17px;
                      "
                    >
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(189, 155, 25);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          问题整改详情
                        </div>
                        ：{{ item.problem_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        问题整改日期：{{ item.created_time }}
                      </div>
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(166, 145, 92);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          餐厅回复
                        </div>
                        ：{{ item.feedback_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        回复日期：{{ item.feedback_user_time }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
          <!-- 抓拍部分 -->
          <div class="box-right-three" v-else>
            <ChartBox title="AI预警抓拍">
              <div v-if="!Snap.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item v-for="(item, index) in Snap" :key="index">
                  <div class="right-li">
                    <img
                      class="img"
                      :src="item.img"
                      alt=""
                      @click="viewLargeImage(item.img, index)"
                    />
                    <div>
                      <div class="Title">{{ item.title }}</div>
                      <div class="time">抓拍时间：{{ item.time }}</div>
                      <div class="name">
                        抓拍地点：
                        <div>{{ item.address }}</div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import StoreManager from "../components/storeManager.vue";
import Swiper from "../components/swiper.vue";
import { baseImgUrl } from "../utils/request";
import Rectification from "../components/rectification.vue";
import {
  getAiBox,
  getShopImage,
  getTemperature,
  getSuggest,
  getIotInfoV2,
  getshowImgs,
  getHealthTable,
  getBlocks,
  getTaskRecordByStoreId,
  getVideos,
  getProblemList,
  getSuggestion,
} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      suggestionContent: [], //顾客建议
      rectificationContent: [],
      videoTitle: "",
      videoSources: [],
      currentVideoIndex: 0,
      secondContent: [],
      secondContentSrc: [],
      AiHeaderQuery: false,
      isPic: true,
      bigPicIndex: 0,
      dataList: [],
      cleanUpMemory: null,
      healthList: [],
      locationTempList: [],
      suggestImg: "",
      tableTeight: "530",
      tabList: [],
      TemperatureDate: [], //温度日期
      tempDate: [],
      TemperatureVal: [], //温度数值
      WetDate: [], //湿度数值
      tempArr: [
        {
          num: 28,
          name: "最高温度",
        },
        {
          name: "最低温度",
          num: 0,
        },
      ],
      humidityArr: [
        {
          name: "最高湿度",
          num: 100,
        },
        {
          name: "最低湿度",
          num: 0,
        },
      ],
      bigPic: "",
      isLocal: "", //是否是本地
      index: 0,
      videoList: [],
      currentImgList: [],
      currentVideo: [],
      imageRequestData: {
        page: 1,
      },
      indexs: -1,
      isShowImg: false,
      // arr: [],
      datas: [],
      Snap: [],
      module2: "",
      module4: "",
      module5: "",
      module6: "",
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  methods: {
    // 顾客建议
    async getSuggestion() {
      const res = await getSuggestion();
      this.suggestionContent = res.data.data[0].data.map((item) => {
        return {
          ...item,
          images: baseImgUrl + item.images,
        };
      });
    },
    // 去问题整改页面
    toRectification() {
      this.$router.push({ path: "/rectificationRecords" });
    },
    playNextVideo() {
      this.currentVideoIndex++;
      if (this.currentVideoIndex >= this.videoSources.length) {
        this.currentVideoIndex = 0; // 循环播放
      }
      // this.$refs.myVideo.play();
    },
    async getTaskRecordByStoreId() {
      const res = await getTaskRecordByStoreId({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay,
      });
      if (res.data.code === 0) {
        this.secondContent = [];
      } else {
        Object.values(res?.data.data)[0].task.forEach((item) => {
          this.secondContent.push(item);
          item.arr_photo_url.forEach((photoUrl) => {
            let obj = {
              photoUrl: photoUrl,
              describle: item.describle,
            };
            this.secondContentSrc.push(obj);
            // 创建一个对象来存储相同describle的photoUrl
            let photoUrlMap = [];

            // 遍历secondContentSrc数组，将相同describle的photoUrl存储到photoUrlMap中
            this.secondContentSrc.forEach((item) => {
              photoUrlMap.push(item.photoUrl);
            });

            // 往secondContentSrc数组中的每个item添加photoUrlList数组
            this.secondContentSrc.forEach((item) => {
              item.photoUrlList = photoUrlMap;
            });
          });
        });
      }
    },
    toAssistantManager() {
      this.$router.push({ path: "/StoreManager" });
    },
    handleChildEvent() {
      this.tabList = [];
      this.dataList = [];
      this.healthList = [];
      this.isLocal = "";
      this.datas = [];
      this.Snap = [];
      this.suggestImg = "";
      this.locationTempList = [];
      this.getBlocksTable();
      this.getshowImgs();
      this.getHealthTable();
      this.getAiBox();
      this.getImage();
      this.getSuggests();
      this.getIotInfoV();
    },
    // checkbox的change事件
    changeCheckbox(e) {
      this.index = 0;
      this.indexs = -1;
      this.checked = e;
      if (this.checked) {
        this.currentVideo = [];
      } else {
        this.currentVideo = [];
        this.getAiBox();
      }
    },
    // 播放事件
    // play(a) {
    //   this.index++;
    //   if (a.children) {
    //     this.index = 0;
    //     this.indexs = -1;
    //     Array.prototype.push.apply(this.videoList, a.children);
    //   } else {
    //     this.indexs++;
    //     this.currentImg = !this.checked ? a.channel_image : a.flv_url;
    //     if (this.index < 5) {
    //       this.currentVideo.splice(this.index - 1, 1, this.currentImg);
    //     } else {
    //       this.indexs = 3;
    //       this.currentVideo.splice(
    //         this.currentVideo.length - 1,
    //         1,
    //         this.currentImg
    //       );
    //     }
    //   }
    // },
    // 查看预警抓拍大图
    viewLargeImage(url, index) {
      this.isPic = true;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 查看大图(视频监控)
    viewLargeVideo(url, index) {
      this.isPic = false;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 上一张
    upImage() {
      let prevIndex = this.bigPicIndex - 1;
      let imgList = this.isPic ? this.Snap : this.currentImgList;
      if (prevIndex < 0) {
        prevIndex = imgList.length - 1;
      }
      this.bigPic = this.isPic ? imgList[prevIndex].img : imgList[prevIndex];
      this.bigPicIndex = prevIndex;
    },
    // 下一张
    nextImage() {
      let nextIndex = this.bigPicIndex + 1;
      let imgList = this.isPic ? this.Snap : this.currentImgList;

      if (nextIndex >= imgList.length) {
        nextIndex = 0;
      }
      this.bigPic = this.isPic ? imgList[nextIndex].img : imgList[nextIndex];
      this.bigPicIndex = nextIndex;
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
    // 获取设备状态和AI设备
    async getAiBox() {
      const res = await getAiBox();
      this.isLocal = res.data.data.aiboxInfo.is_local;
      // 树状结构
      this.datas = [
        {
          label: res.data.data.aiboxInfo.shop_name,
        },
      ];
      this.datas[0].children = res.data.data.channel.map((item, index) => {
        if (index < 4) {
          this.currentVideo.push(item.channel_image);
        }
        return {
          id: item.id,
          label: item.channel_name + "（" + item.channel_address + "）",
          flv_url: item.flv_url,
          channel_image: item.channel_image,
        };
      });
      this.option2 = this.datas[0].children;
      // 自动轮播图片数组
      this.option2.forEach((obj) => {
        // 检查对象中是否有 channel_image 属性
        if (!obj.channel_image?.includes("http")) {
          // 将 channel_image 属性的值添加到 currentImgList 数组中
          this.currentImgList.push(baseImgUrl + obj.channel_image);
        } else {
          this.currentImgList.push(obj.channel_image);
        }
      });
      this.value2 = this.option2[0]?.label;
      if (this.checked) {
        this.currentImg = this.option2[0].flv_url;
      } else {
        this.currentImg = this.option2[0]?.channel_image;
      }
      // this.setTwoChart();
    },
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);      
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: !item.alarm_image?.includes("http")
            ? baseImgUrl + item.alarm_image
            : item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
    },
    // 获取视频
    async getVideos() {
      const res = await getVideos();
      this.videoTitle = res.data.data[0].title;
      this.videoSources = res.data.data[0].data;
    },
    // 获取问题详情
    async getProblemList() {
      const res = await getProblemList();
      this.rectificationContent = res.data.data[0].data;
    },
    // 获取温温度数据getTemperature,getSmoke,getImmersionAmount
    async getTemperatures() {
      const res = await getTemperature();
      this.tempArr[0].num = res.data.data.max_temp;
      this.tempArr[1].num = res.data.data.min_temp;
      this.humidityArr[0].num = res.data.data.max_wet;
      this.humidityArr[1].num = res.data.data.min_wet;
      for (var i = 0; i < res.data.data.temp.date.length; i++) {
        var item = res.data.data.temp.date[i];
        var truncatedItem = item.substring(5, 10);
        this.TemperatureDate.push(truncatedItem);
      }
      this.TemperatureVal = res.data.data.temp.val;
      this.tempDate = this.TemperatureVal; //初始温度数值
      this.WetDate = res.data.data.wet.val;
      // 烟雾
      this.smogVal = res.data.data.smog.val;
      this.smogStatus = res.data.data.smog.status;
      this.smogAlertTimes = res.data.data.smog.alertTimes;
      // 浸水
      this.waterStatus = res.data.data.water.status;
      this.waterAlertTimes = res.data.data.water.alertTimes;
      this.waterDate = res.data.data.water.val / 100;
    },
    // 获取客诉
    async getSuggests() {
      const res = await getSuggest();
      if (!res.data.data.imgs[0]?.includes("http")) {
        this.suggestImg = baseImgUrl + res.data.data.imgs[0];
      } else {
        this.suggestImg = res.data.data.imgs[0];
      }
    },
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();
      this.locationTempList = res.data.data.temp;
    },
    // 获取信息展示
    async getshowImgs() {
      const res = await getshowImgs({ from: "home" });
      this.dataList = res.data.data.imgs.map((item) => {
        if (!item.includes("http")) {
          item = baseImgUrl + item;
        }
        return item;
      });
    },
    // 健康证信息展示
    async getHealthTable() {
      const res = await getHealthTable({
        order: "desc",
        orderby: "end_date",
      });
      this.healthList = res.data.data[0].data.map((item) => {
        // 判断对象中是否有 pic_image 属性
        if (!item.pic_image?.includes("http")) {
          item.pic_image = baseImgUrl + item.pic_image;
        }
        return item;
      });
    },
    // 获取大屏展示内容
    async getBlocksTable() {
      const res = await getBlocks({ from: "home" });
      this.tabList = res.data.data.map((str) => {
        return { label: str };
      });
      this.tabList.push({ label: "食品添加剂" }, { label: "食品保质期" });
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
    Swiper,
    StoreManager,
    Rectification,
  },
  created() {
    this.getBlocksTable();
    this.getshowImgs();
    this.getHealthTable();
    this.getAiBox();
    this.getImage();
    this.getSuggests();
    this.getIotInfoV();
    this.getTaskRecordByStoreId();
    this.getVideos();
    this.getProblemList();
    this.getSuggestion();
    this.cleanUpMemory = setInterval(() => {
      this.getAiBox();
      this.getImage();
    }, 15 * 60 * 1000);
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
    const responseData = JSON.parse(localStorage.getItem("show_block")); // 后端返回的数据结构
    Object.keys(responseData).forEach((key) => {
      if (key === "2") {
        this.module2 = responseData[key]; // 将键值为2的value放入module2中
      } else if (key === "5") {
        this.module5 = responseData[key]; // 将键值为5的value放入module5中
      } else if (key === "6") {
        this.module6 = responseData[key]; // 将键值为6的value放入module6中
      } else if (key === "4") {
        this.module4 = responseData[key]; // 将键值为6的value放入module6中
      }
      //以根据需要添加更多的条件判断和对应的module赋值
    });
  },

  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;

  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span {
        font-size: 15px;
      }
    }
  }

  .box-left-whea {
    grid-area: 1/5/2/6;

    .wheaItem {
      width: 49%;
      height: calc(100% - 45px);

      .wea {
        .wea-sunny {
          i {
            color: rgb(86, 181, 249);
            font-size: 28px;
            margin-right: 5px;
          }
        }

        span {
          color: white;
          font-size: 17px;
        }
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 620px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
// 温湿度监控
.box-left-two {
  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}
// 视频监控
.center-top {
  .one {
    box-sizing: border-box;
    width: 100%;

    margin: 0 auto;
    height: 100%;
    color: white;
    img {
      height: 260px;
      width: 350px;
    }
  }
}
// 证件表格
.center-buttom {
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}
// 抓拍部分
.box-right-three {
  .right-ul {
    display: flex;
    flex-direction: column;
    max-height: 98%;

    .right-li {
      display: flex;
      width: 94%;
      height: 100%;
      padding: 2%;
      background-image: url("../assets/image/img-backgroud.png");

      .Title {
        font-size: 16px;
        color: white;
        font-weight: bolder;
        margin-bottom: 8px;
      }

      .img {
        width: 72%;
        height: 95%;
        border-radius: 5px;
        margin-right: 10px;
      }

      .time,
      .name {
        font-size: 12px;
        color: white;
        margin-top: 15px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  padding: 20px 7px;
  color: white !important;
  background-color: rgb(1, 20, 49) !important;
}
// 表头
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 20px 0 !important;
  font-size: 16px;
  border: none !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #a27b67;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}
::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
