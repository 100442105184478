<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :storage="true"
      ></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div class="box-left-one">
            <ChartBox title="园区信息">
              <div
                class="logo"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                "
              >
                <img
                  src=" https://shuwangysa.oss-cn-hangzhou.aliyuncs.com/imgs/202408/ysgyl.png"
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-left: 5px;
                    margin-top: 50px;
                  "
                />
                <div style="color: white">
                  <div
                    class="phone"
                    style="margin: 10px 0; width: 240px; font-weight: bolder"
                  >
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 10px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>占地面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>园区人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                  <div
                    class="shop_address"
                    style="line-height: 1.5; width: 240px"
                  >
                    <span>园区地址：</span>
                    <span>{{ shop_address }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- 企业宣传视频 -->
          <div class="box-left-two" v-if="module2 == 'videos'">
            <ChartBox :title="videoTitle">
              <div
                class="logo"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <video
                  controls
                  ref="myVideo"
                  muted="muted"
                  :loop="videoSources.length == 1 ? true : false"
                  autoplay="autoplay"
                  :src="videoSources[currentVideoIndex].video_url"
                  style="width: 100%; height: 230px; object-fit: cover"
                  @ended="playNextVideo"
                ></video>
              </div>
            </ChartBox>
          </div>
          <!-- 顾客建议 -->
          <div
            class="box-right-three"
            v-else-if="module2 == 'customerSuggestion'"
          >
            <ChartBox title="顾客建议">
              <div v-if="!suggestionContent.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in suggestionContent"
                  :key="index"
                >
                  <div class="right-li">
                    <el-image
                      class="img"
                      :src="item.images"
                      :preview-src-list="[item.images]"
                    ></el-image>
                    <div
                      style="
                        width: 100%;
                        padding: 6px 4px 4px 8px;
                        border-radius: 5px;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        background-color: rgba(39, 150, 28, 0.4);
                        bottom: 0;
                        left: 0;
                      "
                    >
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(189, 155, 25);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          顾客建议
                        </div>
                        ：{{ item.suggestio_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        建议日期：{{ item.created_time }}
                      </div>
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          word-wrap: break-word;
                          max-height: 3em;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            background-color: rgb(166, 145, 92);
                            padding: 2px;
                            border-radius: 5px;
                          "
                        >
                          餐厅回复
                        </div>
                        ：{{ item.feedback_desc }}
                      </div>
                      <div style="margin-top: 5px">
                        回复日期：{{ item.feedback_user_time }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
          <div class="box-left-two" @click="goToTemperHumidity" v-else>
            <ChartBox title="温湿度监控" :tip="true">
              <div class="temperContainer">
                <div
                  class="tempItem"
                  v-for="(item, index) in locationTempList"
                  :key="index"
                >
                  <div class="location">{{ item.name }}</div>
                  <!-- 温度 -->
                  <div class="temper">
                    <!-- 正常 -->
                    <img
                      v-if="item.temp_status == '正常'"
                      src="../assets/image/normal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 警告 -->
                    <img
                      v-if="item.temp_status == '异常'"
                      src="../assets/image/abnormal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 报警 -->
                    <img
                      v-if="item.temp_status == '警告'"
                      src="../assets/image/warn1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <div class="temperNum">{{ item.temp }}℃</div>
                  </div>
                  <!-- 湿度 -->
                  <div class="water">
                    <!-- 正常 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '正常'"
                      src="../assets/image/fullwater.png"
                    />
                    <!-- 警告 wet_status -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '异常'"
                      src="../assets/image/warter2.png"
                    />
                    <!-- 报警 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '警告'"
                      src="../assets/image/warter3.png"
                    />
                    <div class="waterNum">{{ item.wet }}%</div>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
          <!-- 健康证展示 -->
          <div class="box-left-three" @click="goToHealthyList">
            <ChartBox title="健康证展示" more="更多">
              <el-carousel height="230px" indicator-position="none">
                <el-carousel-item
                  v-for="(item, index) in healthList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <img
                      :src="
                        item.pic_image +
                        '?x-oss-process=image/resize,w_280,m_lfit'
                      "
                      alt=""
                      style="width: 270px; height: 130px; margin-top: 10px"
                    />
                    <div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                          margin: 5px 20px 10px 0;
                        "
                      >
                        健康证有效
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                        "
                      >
                        到期日期：{{ item.end_date }}
                      </div>
                    </div>
                    <div style="color: white; font-size: 12px">
                      <div class="heaName">姓名：{{ item.name }}</div>
                      <div class="heaName" style="margin: 5px 0">
                        健康证编号：{{ item.code }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </aside>
        <section>
          <div class="storageContainer">
              <div class="top">
                <div class="left" data-title="运营数据">
                  <div class="group">
                    <div
                      class="item"
                      v-for="(item, index) in storageList"
                      :key="index"
                    >
                      <div class="groupTitle">
                        {{ item.title }}
                      </div>
                      <div style="display: flex">
                        <div class="num">
                          {{ item.today }}
                        </div>
                        <div
                          style="
                            display: flex;
                            font-size: 14px;
                            text-align: center;
                            line-height: 20px;
                            padding-top: 10px;
                          "
                        >
                          <div class="unit">{{ item.titled }}</div>
                          <div class="unit" style="margin-left: 5px">
                            {{ item.yestoday }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="right" data-title="待处理单据">
                  <div class="left-bottom-container">
                    <div
                      class="left-bottom-item"
                      v-for="(item, index) in documentList"
                      :key="index"
                    >
                      <div class="left-bottom-img">
                        <img :src="item.img" />
                      </div>
                      <div class="left-bottom-num">
                        {{ item.num }}
                      </div>
                      <div class="left-bottom-title">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="center" data-title="质检报告轮询展示">
                  <div>
                    <el-carousel indicator-position="none">
                      <el-carousel-item
                        v-for="(item, index) in qualityReport"
                        :key="index"
                      >
                        <div>
                          <el-image
                            style="width: 100%; height: 250PX; margin-top: 50px"
                            :src="item"
                            :preview-src-list="qualityReport"
                          ></el-image>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="left-bottom" data-title="订单分拣进度">
                  <div>
                    <sortingProgress></sortingProgress>
                  </div>
                  <div>
                    <detailedProgress></detailedProgress>
                  </div>
                </div>
                <div class="right-bottom" data-title="配送货品分类占比">
                  <proportion></proportion>
                </div>
              </div>
            </div>
        </section>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import Swiper from "../components/swiper.vue";
import { baseImgUrl } from "../utils/request";
import { getApi, getIotInfoV2, getHealthTable } from "../api/api";
import sortingProgress from "../components/sortingProgress.vue";
import detailedProgress from "../components/detailedProgress.vue";
import proportion from "../components/proportion.vue";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      storageList: [
        {
          title: "今日销售订单数（笔）",
          today: 1,
          titled: "昨日销售订单数",
          yestoday: 0,
        },
        {
          title: "今日下单客户数（个）",
          today: 1,
          titled: "昨日下单客户数",
          yestoday: 0,
        },
        {
          title: "当前合作客户数（个）",
          today: 1,
          titled: "昨日合作客户数",
          yestoday: 0,
        },
      ],
      documentList: [
        {
          title: "待采购入库",
          num: 1,
          img: require("../assets/image/tubiao1.png"),
        },
        {
          title: "待审核货品定价单",
          num: 1,
          img: require("../assets/image/tubiao5.png"),
        },
        {
          title: "等待分拣订单",
          num: 1,
          img: require("../assets/image/tubiao2.png"),
        },
        {
          title: "分拣中订单",
          num: 1,
          img: require("../assets/image/tubiao3.png"),
        },
      ],
      baseImgUrl: "https://aishop2.ufoodlife.cn/getImg.php?image=http://www.shuwangerp.com/",
      qualityReport: [],
      suggestionContent: [], //顾客建议
      videoTitle: "",
      videoSources: [],
      currentVideoIndex: 0,
      healthList: [],
      locationTempList: [],
      tableTeight: "530",
      index: 0,
      currentVideo: [],
      Snap: [],
      module2: "",
      module4: "",
      module5: "",
      module6: "",
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  methods: {
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();
      this.locationTempList = res.data.data.temp;
    },
    // 健康证信息展示
    async getHealthTable() {
      const res = await getHealthTable({
        order: "desc",
        orderby: "end_date",
      });
      this.healthList = res.data.data[0].data.map((item) => {
        // 判断对象中是否有 pic_image 属性
        if (!item.pic_image?.includes("http")) {
          item.pic_image = baseImgUrl + item.pic_image;
        }
        return item;
      });
    },
    async getApiERP() {
      const res = await getApi("getLeftTopData");
      this.storageList[0].today = res.data.data.depotin.today;
      this.storageList[0].yestoday = res.data.data.depotin.yestoday;
      this.storageList[1].today = res.data.data.used.today;
      this.storageList[1].yestoday = res.data.data.used.yestoday;
      this.storageList[2].today = res.data.data.business.today;
      this.storageList[2].yestoday = res.data.data.business.yestoday;
    },
    async getApiERP2() {
      const res = await getApi("getRightTopData");
      this.documentList[0].num = res.data.data.depotin;
      this.documentList[1].num = res.data.data.multipleflow;
      this.documentList[2].num = res.data.data.dispatchsort;
      this.documentList[3].num = res.data.data.dispatchsort_ing;
    },
    async getApiERP3() {
      const res = await getApi("getMiddleMainData");
      res.data.data.quality_report.forEach((item) => {
        this.qualityReport.push(this.baseImgUrl + item);
      });      
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    Swiper,
    sortingProgress,
    detailedProgress,
    proportion,
  },
  created() {
    this.getHealthTable();
    this.getIotInfoV();
    this.getApiERP();
    this.getApiERP2();
    this.getApiERP3();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";   
  },
};
</script>
<style lang="scss" scoped>
.storageContainer {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
} 


.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47%;
}

.left,
.center {
  position: relative;
  width: 30%;
  // height: calc(100% - 90px);
  height: 385px;
  border-radius: 5px;
  padding: 35px 20px;
  box-shadow: 0px 0px 10px 0px #2adcff inset;
}
.left {
  .item {
    width: calc(100% - 32px);
    height: 58px;
    padding: 16px;
    background: linear-gradient(225deg, #6ea9fe 0%, #817bfe 100%);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 30px;
    .groupTitle {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
    .num {
      font-size: 24px;
      line-height: 20px;
      padding-top: 10px;
      flex: 1;
    }
  }
  .item:nth-of-type(2) {
    background: linear-gradient(225deg, #ffaf00 0%, #ff8c64 100%);
  }
  .item:nth-of-type(3) {
    background: linear-gradient(225deg, #bd5eff 0%, #a45bfb 100%);
  }
}
.right {
  .left-bottom-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    .left-bottom-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      text-align: center;
      .left-bottom-img {
        margin-bottom: 12px;
        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }
      }
      .left-bottom-num {
        font-size: 17px;
        font-weight: bolder;
      }
      .left-bottom-title {
        font-size: 13px;
        font-weight: 500;
        color: #9da5ab;
        margin-top: 5px;
      }
    }
  }
}

.right {
  position: relative;
  // flex: 1;
  width: 33%;
  // height: calc(100% - 90px);
  height: 385px;
  margin: 0 15px;
  border-radius: 5px;
  padding: 35px 20px;
  box-shadow: 0px 0px 10px 0px #2adcff inset;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  .left-bottom {
    display: flex;
  }
}

.left-bottom,
.right-bottom {
  position: relative;
  width: 49.5%;
  height: 100%;
  border-radius: 5px;
  // margin-top: 20px;
  box-shadow: 0px 0px 10px 0px #2adcff inset;
}
/* 添加标题样式 */
.right:before,
.left:before,
.center:before,
.left-bottom:before,
.right-bottom:before {
  content: attr(data-title);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #25a6f3;
  color: #fff;
  padding: 0px 30px;
  border-radius: 5px;
  border-bottom: 10px solid #25a6f3;
  padding-top: 5px;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  z-index: 1;
}

.left-bottom:before,
.right-bottom:before {
  padding-top: 10px;
}
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;

  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  height: 100%;
  width: 100%;
  background: linear-gradient(170deg, #000a65 0%, #020924 100%);
  //   background: url("../assets/image/bg.577ccc4c.jpg");

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span {
        font-size: 15px;
      }
    }
  }

  .box-left-whea {
    grid-area: 1/5/2/6;

    .wheaItem {
      width: 49%;
      height: calc(100% - 45px);

      .wea {
        .wea-sunny {
          i {
            color: rgb(86, 181, 249);
            font-size: 28px;
            margin-right: 5px;
          }
        }

        span {
          color: white;
          font-size: 17px;
        }
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > aside {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 620px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
// 温湿度监控
.box-left-two {
  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}
// 视频监控
.center-top {
  .one {
    box-sizing: border-box;
    width: 100%;

    margin: 0 auto;
    height: 100%;
    color: white;
    img {
      height: 260px;
      width: 350px;
    }
  }
}
// 证件表格
.center-buttom {
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}
// 抓拍部分
.box-right-three {
  .right-ul {
    display: flex;
    flex-direction: column;
    max-height: 98%;

    .right-li {
      display: flex;
      width: 94%;
      height: 100%;
      padding: 2%;
      background-image: url("../assets/image/img-backgroud.png");

      .Title {
        font-size: 16px;
        color: white;
        font-weight: bolder;
        margin-bottom: 8px;
      }

      .img {
        width: 72%;
        height: 95%;
        border-radius: 5px;
        margin-right: 10px;
      }

      .time,
      .name {
        font-size: 12px;
        color: white;
        margin-top: 15px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  padding: 20px 7px;
  color: white !important;
  background-color: rgb(1, 20, 49) !important;
}
// 表头
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 20px 0 !important;
  font-size: 16px;
  border: none !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #a27b67;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}
::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
